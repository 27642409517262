import React from "react";
import styles from "./footerFilter.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "utils/language/layout";
import { Collapse } from "antd";
import Link from "next/link";
import regions from "./regions.json";
import { useGetMainRegions } from "pages/main-layout/services/queries";
const {
  Panel
} = Collapse;
const FooterFilter = () => {
  const {
    query,
    locale,
    pathname
  } = useRouter();
  const {
    data
  } = useGetMainRegions();
  const Cities = () => <div className={styles.container_elements} data-sentry-component="Cities" data-sentry-source-file="FooterFilter.tsx">
      {regions?.map(item => {
      return <Link href={pathname?.includes("kvartiri") ? `/gorod-tashkent-${item?.id}/kvartiri?region_id=12&district_id=${item?.id}` : `/gorod-tashkent-${item?.id}/novostroyki?region_id=12&district_id=${item?.id}`} key={item?.id}>
            <p className={`${item?.id === Number(query?.district_id) ? "text-[#086ADA]" : "text-[#3A4252]"} text-base font-normal cursor-pointer`}>
              {locale === "uz" ? item?.name?.replace("tumani", "") : item?.name_ru?.replace("район", "")}
            </p>
          </Link>;
    })}
    </div>;
  const Regions = () => <div className={styles.container_elements} data-sentry-component="Regions" data-sentry-source-file="FooterFilter.tsx">
      {data?.map(item => {
      return <Link href={pathname?.includes("kvartiri") ? `/${item?.url}/kvartiri?region_id=${item?.id}` : `/${item?.url}/novostroyki?region_id=${item?.id}`} key={item?.id}>
            <p className={`${item?.id === Number(query?.region_id) ? "text-[#086ADA]" : "text-[#3A4252]"} text-base font-normal cursor-pointer`}>
              {locale === "uz" ? item?.name?.uz?.replace("viloyati", "") : item?.name?.ru?.replace("область", "")}
            </p>
          </Link>;
    })}
    </div>;
  return <div className={styles.container} data-sentry-component="FooterFilter" data-sentry-source-file="FooterFilter.tsx">
      <div className={styles.container_body}>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri") ? layoutLang[locale as keyof typeof layoutLang]["Toshkentdagi_yangi_kvartiralar"] : layoutLang[locale as keyof typeof layoutLang]["Toshkentdagi_yangi_uylar"]}
          </h3>
          <Cities data-sentry-element="Cities" data-sentry-source-file="FooterFilter.tsx" />
        </div>
        <div>
          <h3 className="text-[#101828] text-xl font-semibold mb-6">
            {pathname?.includes("kvartiri") ? layoutLang[locale as keyof typeof layoutLang]["Butun_Ozbekiston_boylab_kvartiralar"] : layoutLang[locale as keyof typeof layoutLang]["Butun_Ozbekiston_boylab_yangi_uylar"]}
          </h3>
          <Regions data-sentry-element="Regions" data-sentry-source-file="FooterFilter.tsx" />
        </div>
      </div>
      <div className={styles.container_bodyCollapse}>
        <Collapse expandIconPosition="end" bordered={false} defaultActiveKey={"1"} data-sentry-element="Collapse" data-sentry-source-file="FooterFilter.tsx">
          <Panel className="bg-white mb-8" header={<h3 className="text-[#101828] text-xl font-semibold ">
                {pathname?.includes("kvartiri") ? layoutLang[locale as keyof typeof layoutLang]["Toshkentdagi_yangi_kvartiralar"] : layoutLang[locale as keyof typeof layoutLang]["Toshkentdagi_yangi_uylar"]}
              </h3>} key={"1"} data-sentry-element="Panel" data-sentry-source-file="FooterFilter.tsx">
            <Cities data-sentry-element="Cities" data-sentry-source-file="FooterFilter.tsx" />
          </Panel>
          <Panel className="bg-white" header={<h3 className="text-[#101828] text-xl font-semibold ">
                {pathname?.includes("kvartiri") ? layoutLang[locale as keyof typeof layoutLang]["Butun_Ozbekiston_boylab_kvartiralar"] : layoutLang[locale as keyof typeof layoutLang]["Butun_Ozbekiston_boylab_yangi_uylar"]}
              </h3>} key={"2"} data-sentry-element="Panel" data-sentry-source-file="FooterFilter.tsx">
            <Regions data-sentry-element="Regions" data-sentry-source-file="FooterFilter.tsx" />
          </Panel>
        </Collapse>
      </div>
    </div>;
};
export default FooterFilter;