import { useRouter } from "next/router";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../../store/reducers/userReducer";
import { rootPaths } from "../../../../../utils/constants/rootPaths";
import { menuLinks } from "../../../utils/constants/menuLinks";
import { layoutLang } from "../../../../../utils/language/layout";
import Link from "next/link";
import FavouriteIcon from "../../../../../assets/icons/FavouriteIcon";
import UysotIcon from "../../../../../assets/icons/UysotIcon";
import CloseIcon from "../../../../../assets/icons/CloseIcon";
import HamburgerIcon from "../../../assets/icon/HamburgerIcon";
import styles from "./HeaderBottom.module.scss";
import HeaderDropDown from "../header-dropdown/HeaderDropDown";
const HeaderBottom: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    push,
    pathname,
    locale
  } = useRouter();
  const {
    toggleVisibleMobileMenu,
    setChangeMenu
  } = userReducerActions;
  const {
    userAddress
  } = useAppSelector(state => state.userReducer);
  const {
    menuIcon
  } = useAppSelector(state => state.userReducer);

  // location
  const location = userAddress?.url_name;

  //distrcit_id
  const district_id = userAddress?.district_id;

  // back to main
  const backToMain = () => {
    push(rootPaths.INDEX);
  };

  // handle open mobile menu
  const handleOpenMobilMenu = () => {
    dispatch(toggleVisibleMobileMenu());
    dispatch(setChangeMenu(!menuIcon));
  };
  return <div className={styles.header_bottom} data-sentry-component="HeaderBottom" data-sentry-source-file="HeaderBottom.tsx">
      <div className="wrapper">
        <div className={styles.header_bottom_content}>
          <div className={styles.header_bottom_content_left}>
            {/* {!md && ( */}
            {menuIcon ? <CloseIcon className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`} onClick={handleOpenMobilMenu} /> : <HamburgerIcon className={`${styles.header_bottom_content_left_menuIcon} ${styles.hamburger}`} onClickFunc={handleOpenMobilMenu} />}
            {/* )} */}
            <div onClick={backToMain} className={styles.logo} aria-label="logo">
              <UysotIcon data-sentry-element="UysotIcon" data-sentry-source-file="HeaderBottom.tsx" />
            </div>
          </div>
          <nav className="desktopD" aria-hidden suppressHydrationWarning>
            <ul className={styles.header_bottom_content_center}>
              {menuLinks(location, district_id || 158).map((item, index) => <li key={index}>
                  <Link href={item.path} className={item.basePath === pathname ? styles.active : ""}>
                    {layoutLang[String(locale) as any]![String(item?.label)]}
                  </Link>
                </li>)}
            </ul>
          </nav>
          <div className={`${styles.header_bottom_content_right} mobileD`} aria-hidden suppressHydrationWarning>
            <HeaderDropDown data-sentry-element="HeaderDropDown" data-sentry-source-file="HeaderBottom.tsx" />
            <div className={styles.item}>
              <Link href={rootPaths.FAVOURITES} legacyBehavior data-sentry-element="Link" data-sentry-source-file="HeaderBottom.tsx">
                <a>
                  <FavouriteIcon data-sentry-element="FavouriteIcon" data-sentry-source-file="HeaderBottom.tsx" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>;
};
export default HeaderBottom;