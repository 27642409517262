import React from "react";
type Props = {
  onClick?: () => void;
  className?: string;
};
const CloseIcon: React.FC<Props> = ({
  onClick,
  className
}) => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} className={className ?? ""} cursor="pointer" data-sentry-element="svg" data-sentry-component="CloseIcon" data-sentry-source-file="CloseIcon.tsx">
      <path d="M11.175 10L16.425 4.75837C16.5819 4.60145 16.6701 4.38862 16.6701 4.1667C16.6701 3.94478 16.5819 3.73195 16.425 3.57503C16.2681 3.41811 16.0552 3.32996 15.8333 3.32996C15.6114 3.32996 15.3986 3.41811 15.2417 3.57503L9.99999 8.82503L4.75832 3.57503C4.6014 3.41811 4.38857 3.32996 4.16666 3.32996C3.94474 3.32996 3.73191 3.41811 3.57499 3.57503C3.41807 3.73195 3.32991 3.94478 3.32991 4.1667C3.32991 4.38862 3.41807 4.60145 3.57499 4.75837L8.82499 10L3.57499 15.2417C3.49688 15.3192 3.43489 15.4113 3.39258 15.5129C3.35027 15.6144 3.32849 15.7234 3.32849 15.8334C3.32849 15.9434 3.35027 16.0523 3.39258 16.1538C3.43489 16.2554 3.49688 16.3476 3.57499 16.425C3.65246 16.5031 3.74463 16.5651 3.84618 16.6074C3.94773 16.6497 4.05665 16.6715 4.16666 16.6715C4.27667 16.6715 4.38559 16.6497 4.48714 16.6074C4.58869 16.5651 4.68085 16.5031 4.75832 16.425L9.99999 11.175L15.2417 16.425C15.3191 16.5031 15.4113 16.5651 15.5128 16.6074C15.6144 16.6497 15.7233 16.6715 15.8333 16.6715C15.9433 16.6715 16.0523 16.6497 16.1538 16.6074C16.2554 16.5651 16.3475 16.5031 16.425 16.425C16.5031 16.3476 16.5651 16.2554 16.6074 16.1538C16.6497 16.0523 16.6715 15.9434 16.6715 15.8334C16.6715 15.7234 16.6497 15.6144 16.6074 15.5129C16.5651 15.4113 16.5031 15.3192 16.425 15.2417L11.175 10Z" fill="#7C7C7C" data-sentry-element="path" data-sentry-source-file="CloseIcon.tsx" />
    </svg>;
};
export default CloseIcon;