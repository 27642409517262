import React from "react";
import HeartIcon from "assets/icons/HeartIcon";
import Link from "next/link";
import { useRouter } from "next/router";
import FooterMapIcon from "assets/icons/FooterMapIcon";
import ComplexIcon from "assets/icons/ComplexIcon";
import ApartmentIcon from "assets/icons/ApartmentIcon";
const MobileFooterMenu = () => {
  const {
    locale,
    pathname
  } = useRouter();
  const menuItems = [{
    text: {
      uz: "Yangi binolar",
      ru: "Новостройки"
    },
    children: <ComplexIcon fill={pathname?.includes("novostroyki") ? "#0DC683" : "#475467"} />,
    link: "/gorod-tashkent-158/novostroyki",
    url: "novostroyki"
  }, {
    text: {
      uz: "Xonadonlar",
      ru: "Квартиры"
    },
    children: <ApartmentIcon fill={pathname?.includes("kvartiri") ? "#0DC683" : "#475467"} />,
    link: "/gorod-tashkent-158/kvartiri",
    url: "kvartiri"
  }, {
    text: {
      uz: "Sevimlilar",
      ru: "Избранное"
    },
    children: <HeartIcon stroke={pathname?.includes("favourites") ? "#0DC683" : "#475467"} width={20} height={20} />,
    link: "/favourites",
    url: "favourites"
  }, {
    text: {
      uz: "Xarita",
      ru: "Карта"
    },
    children: <FooterMapIcon fill={pathname?.includes("karta") ? "#0DC683" : "#475467"} />,
    link: "/karta",
    url: "karta"
  }];
  const active = "bg-[#0DC6831A] flex items-center justify-center h-8 w-[64px] rounded-2xl";
  return <div className="fixed bottom-0 bg-white z-[1000] w-full sm:hidden grid grid-cols-4 items-center justify-between border border-solid border-[#EAECF0] py-4 px-2" data-sentry-component="MobileFooterMenu" data-sentry-source-file="MobileFooterMenu.tsx">
      {menuItems?.map(item => {
      return <Link href={item?.link} key={item?.text[String(locale) as keyof typeof item.text]} className={"flex flex-col items-center gap-1"}>
            <div className={pathname.includes(item?.url) ? active : "flex items-center justify-center h-8 w-[64px]"}>
              {item?.children}
            </div>
            <p className={`${pathname.includes(item?.url) ? "text-[#1D2939]" : "text-[#667085]"}  text-xs font-medium`}>
              {item?.text[String(locale) as keyof typeof item.text]}
            </p>
          </Link>;
    })}
    </div>;
};
export default MobileFooterMenu;