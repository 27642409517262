import { Form, Modal } from "antd";
import { useTranslations } from "next-intl";
import React, { useMemo, useState } from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { phoneNumberFixer } from "../../../../utils/helpers/phoneNumberFixer";
import { useLogin, useRegister } from "../../services/mutations";
import Confirmation from "./confirmation/Confirmation";
import Phone from "./phone/Phone";
import styles from "./loginModal.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../utils/language/layout";
type FormFields = {
  phone: string;
  sms_code: string;
};
const LoginModal: React.FC = () => {
  const t = useTranslations("header.loginModal");
  const {
    locale
  } = useRouter();
  const dispatch = useAppDispatch();
  const [formInstance] = Form.useForm();
  const [step, setStep] = useState(1);
  const {
    setVisibleLoginModal,
    setIsAuth
  } = userReducerActions;
  const register = useRegister(handleAfterSuccessRegister);
  const login = useLogin(handleAfterSuccesslogin);
  const {
    visibleLoginModal
  } = useAppSelector(state => state.userReducer);

  // handle cancel
  const handleCancel = () => {
    dispatch(setVisibleLoginModal(false));
  };

  // handle after close
  const handleAfterClose = () => {
    step !== 1 && setStep(1);
    formInstance.resetFields();
  };

  // handle after success register
  function handleAfterSuccessRegister() {
    setStep(2);
  }

  // handle after succes login
  function handleAfterSuccesslogin() {
    handleCancel();
    formInstance.resetFields();
    dispatch(setIsAuth(true));
  }

  // title
  const title = useMemo(() => {
    if (step === 1) return layoutLang[String(locale)]["Войти или создать личный кабинет"];
    return layoutLang[String(locale)]["Введите код из СМС"];
  }, [step]);

  // on finish
  const onFinish = (fields: FormFields) => {
    if (step === 1) register.mutate({
      phone: phoneNumberFixer(fields?.phone)
    });else {
      if (!login.isLoading) {
        login.mutate({
          phone: phoneNumberFixer(fields?.phone),
          sms_code: +fields.sms_code
        });
      }
    }
  };
  return <Modal centered title={null} footer={false} closable={false} onCancel={handleCancel} afterClose={handleAfterClose} open={visibleLoginModal} className={`${styles.login_modal} custom_styles_modal`} data-sentry-element="Modal" data-sentry-component="LoginModal" data-sentry-source-file="LoginModal.tsx">
      <div className="custom_styles_modal_header">
        <h1>{title}</h1>
        <CloseIcon onClick={handleCancel} data-sentry-element="CloseIcon" data-sentry-source-file="LoginModal.tsx" />
      </div>
      <Form layout="vertical" form={formInstance} onFinish={onFinish} autoComplete="off" className={styles.login_modal_body} data-sentry-element="Form" data-sentry-source-file="LoginModal.tsx">
        <Phone isActive={step === 1} isLoading={register.isLoading} data-sentry-element="Phone" data-sentry-source-file="LoginModal.tsx" />
        <Confirmation isLoading={login.isLoading} setStep={setStep} step={step} isActive={step === 2} formInstance={formInstance} data-sentry-element="Confirmation" data-sentry-source-file="LoginModal.tsx" />
      </Form>
    </Modal>;
};
export default LoginModal;