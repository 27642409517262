import { Button, Modal, Spin } from "antd";
import React, { useState } from "react";
import CloseIcon from "../../../../assets/icons/CloseIcon";
import { userReducerActions } from "../../../../store/reducers/userReducer";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { useGetRegionCity, useGetRegionDistricts } from "../../services/queries";
import { useTranslations } from "next-intl";
import { useSetUserAddress } from "../../services/mutations";
import styles from "./addressModal.module.scss";
import { useRouter } from "next/router";
import { RU } from "../../../../utils/constants/locales";
const AddressModal = () => {
  const {
    data: regions,
    isLoading: regionsLoading
  } = useGetRegionCity();
  const [selectedRegionId, setSelectedRegionId] = useState<number>();
  const [step, setStep] = useState(0);
  const {
    data: districts,
    remove,
    isLoading: districtsLoading
  } = useGetRegionDistricts(selectedRegionId, step);
  const {
    setVisibleAddressModal
  } = userReducerActions;
  const {
    visibleAddressModal
  } = useAppSelector(state => state.userReducer);
  const t = useTranslations("header.addressModal");
  const dispatch = useAppDispatch();
  const setUserAddressMutation = useSetUserAddress(handleClose);
  const [cityName, setCityName] = useState("");
  const {
    locale
  } = useRouter();
  function handleClose() {
    dispatch(setVisibleAddressModal(false));
    setStep(0);
    remove();
  }
  const handleRegionCityButtonClick = (id: number, name_ru: string) => {
    setSelectedRegionId(id);
    setCityName(name_ru);
  };
  const handleDistricts = () => {
    setStep(1);
    remove();
  };
  const handleSubmit = (district_id: number) => {
    setUserAddressMutation.mutateAsync({
      district_id,
      city_id: selectedRegionId
    });
  };
  const handleWholeCountryButtonClick = () => {
    const handleDistrictId = 158;
    const handleCityId = 12;
    setUserAddressMutation.mutateAsync({
      district_id: handleDistrictId,
      city_id: handleCityId,
      all: true
    });
  };
  const handleWholeDistrictButtonClick = () => {
    let districtId;
    switch (cityName) {
      case "Бухарская область":
        districtId = 20;
        break;
      case "Андижанская область":
        districtId = 5;
        break;
      case "Кашкадарьинская область":
        districtId = 33;
        break;
      case "Республика Каракалпакстан":
        districtId = 48;
        break;
      case "Навоийская область":
        districtId = 66;
        break;
      case "Наманганская область":
        districtId = 76;
        break;
      case "Самаркандская область":
        districtId = 90;
        break;
      case "Сырдарьинская область":
        districtId = 114;
        break;
      case "Ферганская область":
        districtId = 118;
        break;
      case "Ташкентская область":
        districtId = 154;
        break;
      case "город Ташкент":
        districtId = 158;
        break;
      case "Хорезмская область":
        districtId = 170;
        break;
      case "Джизакская область":
        districtId = 183;
        break;
      case "Сурхандарьинская область":
        districtId = 206;
        break;
      default:
        districtId = 0;
        break;
    }
    setUserAddressMutation.mutateAsync({
      district_id: districtId,
      city_id: selectedRegionId
    });
  };
  function prev() {
    setStep(0);
  }
  return <Modal centered title={null} footer={false} open={visibleAddressModal} closable={false} onCancel={handleClose} className={`${styles.address_modal} custom_styles_modal`} data-sentry-element="Modal" data-sentry-component="AddressModal" data-sentry-source-file="AddressModal.tsx">
      <div className="custom_styles_modal_header">
        <h1>{t("Выбор города или региона")}</h1>
        <CloseIcon onClick={handleClose} data-sentry-element="CloseIcon" data-sentry-source-file="AddressModal.tsx" />
      </div>
      <div className={styles.address_modal_body}>
        <Spin spinning={regionsLoading && step === 0} data-sentry-element="Spin" data-sentry-source-file="AddressModal.tsx">
          {step === 0 && <>
              <div className={styles.city_div}>
                <Button className={`${styles.city_and_district} ${styles.city}`} onClick={() => handleWholeCountryButtonClick()}>
                  {t("Вся страна")}
                </Button>
              </div>
              {regions?.data?.map(region => <Button className={styles.city_and_district} key={region.id} onClick={() => {
            handleDistricts();
            handleRegionCityButtonClick(region.id, region.name_ru);
          }}>
                  <span>{locale === RU ? region.name_ru : region?.name}</span>
                </Button>)}
            </>}
        </Spin>
        <div className={styles.districts}>
          <Spin spinning={districtsLoading && step === 1} data-sentry-element="Spin" data-sentry-source-file="AddressModal.tsx">
            {step === 1 && <>
                <div className={styles.district_div}>
                  <Button className={`${styles.city_and_district}`} onClick={handleWholeDistrictButtonClick}>
                    {cityName}
                  </Button>
                </div>
                {districts?.data?.map(district => <Button className={styles.city_and_district} key={district.id} onClick={() => handleSubmit(district.id)}>
                    <span>
                      {locale === RU ? district.name_ru : district?.name}
                    </span>
                  </Button>)}
                <br />
                <div className={styles.footer}>
                  <Button onClick={prev} className={styles.prev}>
                    {t("Назад")}
                  </Button>
                </div>
              </>}
          </Spin>
        </div>
      </div>
    </Modal>;
};
export default AddressModal;