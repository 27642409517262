import { LOCATION } from "../../../../utils/constants/queryParamsKeys"
import { rootPaths } from "../../../../utils/constants/rootPaths"

export const menuLinks = (
  location: string | undefined,
  district_id: number | undefined
) => {
  return [
    {
      label: "Каталог ЖК",
      path: `/${location}-${district_id}${rootPaths.RESIDENTIAL_COMPLEXES}`,
      basePath: `/[${LOCATION}]${rootPaths.RESIDENTIAL_COMPLEXES}`,
    },
    {
      label: "Карта новостроек",
      path: rootPaths.MAP,
      basePath: rootPaths.MAP,
    },
    {
      label: "Квартиры",
      path: `/${location}-${district_id}${rootPaths.APARTMENTS}`,
      basePath: `/[${LOCATION}]${rootPaths.APARTMENTS}`,
    },

    {
      label: "Журнал",
      path: rootPaths.MAGAZINES,
      basePath: rootPaths.MAGAZINES,
    },
    {
      label: "Tez_tez_beriladigan_savollar",
      path: rootPaths.FAQ,
      basePath: rootPaths.FAQ,
    },
    // {
    //   label: "EXPO 2024",
    //   path: "https://expo.uysot.uz/?utm_source=uysot_uz&utm_medium=site&utm_campaign=menu",
    // },
  ]
}
