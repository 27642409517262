// import { useTranslations } from "next-intl"
import React, { useEffect, useRef } from "react";
import { eventTypes } from "../../../../../utils/constants/eventTypes";
import { localStorageKeys } from "../../../../../utils/constants/localStorageKeys";
// import { LocationRes } from "../../../utils/models/locationModel"
// import LocationPopover from "../location-popover/LocationPopover"

import styles from "./headerTopMobile.module.scss";
import { isBrowser } from "../../../../../utils/helpers/isBrowser";
const HeaderTopMobile: React.FC = () => {
  // const t = useTranslations("header.locationPopover")
  const setUserAddresBtnRef = useRef<HTMLButtonElement>(null);
  // const [location, setLocation] = useState<LocationRes>()

  // handle set user address
  const handleSetUserAddress = () => {
    setUserAddresBtnRef.current?.click();
  };

  // add event listener
  useEffect(() => {
    window.addEventListener(eventTypes.CLICK, handleSetUserAddress);
    return () => {
      window.removeEventListener(eventTypes.CLICK, handleSetUserAddress);
    };
  }, []);
  return <div className={`${styles.header_top} ${isBrowser() && localStorage.getItem(localStorageKeys.ADDRESS) ? styles.passive : ""} mobileD`} aria-hidden data-sentry-component="HeaderTopMobile" data-sentry-source-file="HeaderTopMobile.tsx">
      <div className="wrapper d_f ai_c jc_sb">
        {/*<div className={styles.body}>*/}
        {/*  <p>{t("Из какого региона вы ищете дом?")}</p>*/}
        {/*  <span>{location ? location.city_name : "..."}</span>*/}
        {/*</div>*/}
      </div>
      {/*<LocationPopover*/}
      {/*  btnRef={setUserAddresBtnRef}*/}
      {/*  handleLocation={setLocation}*/}
      {/*/>*/}
    </div>;
};
export default HeaderTopMobile;