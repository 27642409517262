import HeaderBottom from "./bottom/HeaderBottom";
import HeaderTopMobile from "./top-mobile/HeaderTopMobile";
import HeaderTop from "./top/HeaderTop";
import styles from "./header.module.scss";
import { useCheckAuth } from "../../services/queries";
const Header: React.FC = () => {
  // const { md } = Grid.useBreakpoint()
  useCheckAuth();
  return <div className={styles.header} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <HeaderTop data-sentry-element="HeaderTop" data-sentry-source-file="Header.tsx" />
      <HeaderTopMobile data-sentry-element="HeaderTopMobile" data-sentry-source-file="Header.tsx" />
      <HeaderBottom data-sentry-element="HeaderBottom" data-sentry-source-file="Header.tsx" />
    </div>;
};
export default Header;