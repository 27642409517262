import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { phoneNumberFixer } from "../../../../../utils/helpers/phoneNumberFixer";
import { useRegister } from "../../../services/mutations";
import styles from "../loginModal.module.scss";
type Props = {
  phoneNumber: string;
};
const Countdown: React.FC<Props> = ({
  phoneNumber
}) => {
  const t = useTranslations("header.loginModal");
  const [time, setTime] = useState(59);
  const register = useRegister(handleAfterSuccess);

  // after success
  function handleAfterSuccess() {
    setTime(59);
  }

  // start countdown
  useEffect(() => {
    time > 0 && setTimeout(() => setTime(time - 1), 1000);
  }, [time]);

  // handle resend code
  const handleResendCode = () => {
    register.mutate({
      phone: phoneNumberFixer(phoneNumber)
    });
  };
  if (time) {
    return <p className={styles.resend_code_countdown}>
        {t("Повторная отправка будет доступна")}
        <br />
        {t("Через")} <span className={styles.time}>{time}</span> {t("Секунд")}.
      </p>;
  } else {
    return <p className={styles.resend_code} onClick={handleResendCode} aria-hidden>
        {t("Отправить код ещё раз")}
      </p>;
  }
};
export default Countdown;