import ReactInputMask from "react-input-mask";
type Props = {
  mask: string;
  placeholder?: string;
};
export const InputMask: React.FC<Props> = ({
  mask,
  placeholder
}) => {
  return <ReactInputMask mask={mask}
  // @ts-ignore
  maskChar="" className="ant-input" placeholder={placeholder} data-sentry-element="ReactInputMask" data-sentry-component="InputMask" data-sentry-source-file="InputMask.tsx" />;
};