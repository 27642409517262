import { Button, Form } from "antd";
import { useTranslations } from "next-intl";
import React, { useEffect, useState } from "react";
import { InputMask } from "../../../../../common/inputMask/InputMask";
import { useAppSelector } from "../../../../../hooks/reduxHooks";
import { PHONE_MASK } from "../../../../../utils/constants/inputMasks";
import { lengthValidator } from "../../../../../utils/helpers/lengthValidator";
import styles from "../loginModal.module.scss";
import { useRouter } from "next/router";
import { layoutLang } from "../../../../../utils/language/layout";
type Props = {
  isLoading: boolean;
  isActive: boolean;
};
const {
  Item
} = Form;
const Phone: React.FC<Props> = ({
  isLoading,
  isActive
}) => {
  const {
    locale
  } = useRouter();
  const t = useTranslations("header.loginModal");
  const [disableNext, setDisableNext] = useState(true);
  const {
    visibleLoginModal
  } = useAppSelector(state => state.userReducer);

  // handle  success
  const handleSuccess = () => {
    if (disableNext) setDisableNext(false);
  };

  // handle error
  const handleError = () => {
    if (!disableNext) setDisableNext(true);
  };
  useEffect(() => {
    if (!visibleLoginModal) setDisableNext(true);
  }, [visibleLoginModal]);
  return <div className={`${styles.login_modal_phone} ${isActive ? styles.active : ""}`} data-sentry-component="Phone" data-sentry-source-file="Phone.tsx">
      <Item name="phone" label={layoutLang[String(locale)]["Номер телефона"]} rules={[lengthValidator(12, handleSuccess, handleError)]} data-sentry-element="Item" data-sentry-source-file="Phone.tsx">
        {InputMask({
        mask: PHONE_MASK
      })}
      </Item>
      <Button disabled={disableNext} type="primary" htmlType="submit" className={styles.next_btn} loading={isLoading} data-sentry-element="Button" data-sentry-source-file="Phone.tsx">
        {layoutLang[String(locale)]["Продолжить"]}
      </Button>
    </div>;
};
export default Phone;