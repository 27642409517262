import React from "react";
const SuccessMessageIcon = (svg: React.SVGProps<SVGSVGElement>) => {
  return <svg {...svg} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="SuccessMessageIcon" data-sentry-source-file="SuccessMessageIcon.tsx">
      <rect x="3" y="3" width="40" height="40" rx="20" fill="#D1FADF" data-sentry-element="rect" data-sentry-source-file="SuccessMessageIcon.tsx" />
      <rect x="3" y="3" width="40" height="40" rx="20" stroke="#ECFDF3" strokeWidth="6" data-sentry-element="rect" data-sentry-source-file="SuccessMessageIcon.tsx" />
      <g clipPath="url(#clip0_5845_6287)" data-sentry-element="g" data-sentry-source-file="SuccessMessageIcon.tsx">
        <path d="M20.4993 22.9998L22.166 24.6665L25.916 20.9165M19.1108 16.1821C19.7807 16.1286 20.4166 15.8652 20.9281 15.4294C22.1216 14.4122 23.8771 14.4122 25.0706 15.4294C25.5821 15.8652 26.218 16.1286 26.8879 16.1821C28.4511 16.3068 29.6924 17.5481 29.8171 19.1113C29.8706 19.7812 30.134 20.4171 30.5698 20.9286C31.587 22.1221 31.587 23.8776 30.5698 25.0711C30.134 25.5826 29.8706 26.2185 29.8171 26.8884C29.6924 28.4516 28.4511 29.6928 26.8879 29.8176C26.218 29.871 25.5821 30.1345 25.0706 30.5703C23.8771 31.5875 22.1216 31.5875 20.9281 30.5703C20.4166 30.1345 19.7807 29.871 19.1108 29.8176C17.5476 29.6928 16.3063 28.4516 16.1816 26.8884C16.1281 26.2185 15.8647 25.5826 15.4289 25.0711C14.4117 23.8776 14.4117 22.1221 15.4289 20.9286C15.8647 20.4171 16.1281 19.7812 16.1816 19.1113C16.3063 17.5481 17.5476 16.3068 19.1108 16.1821Z" stroke="#039855" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="SuccessMessageIcon.tsx" />
      </g>
      <defs data-sentry-element="defs" data-sentry-source-file="SuccessMessageIcon.tsx">
        <clipPath id="clip0_5845_6287" data-sentry-element="clipPath" data-sentry-source-file="SuccessMessageIcon.tsx">
          <rect width="20" height="20" fill="white" transform="translate(13 13)" data-sentry-element="rect" data-sentry-source-file="SuccessMessageIcon.tsx" />
        </clipPath>
      </defs>
    </svg>;
};
export default SuccessMessageIcon;