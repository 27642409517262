import React, { useState } from "react";
import ExpoLogoIcon from "../../../assets/icons/ExpoLogoIcon";
import Link from "next/link";
import HamburgerMenuIcon from "assets/icons/HamburgerMenuIcon";
import { useRouter } from "next/router";
import { langs } from "pages/expo/constants/langs";
import { cx } from "utils/cx";
const Header = () => {
  const [active, setActive] = useState(false);
  const {
    locale
  } = useRouter();
  const links = [{
    name: langs(locale).Bosh_sahifa,
    url: "/expo"
  }, {
    name: langs(locale).Uy_sotib_oling,
    url: "/"
  }, {
    name: langs(locale).Aloqa,
    url: "/expo#contact"
  }];
  return <div className="fixed z-10 h-[103px] bg-[#0000008C] w-full flex justify-center items-center" data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <div className="flex justify-between max-width px-[32px] md:px-[0] w-full">
        <Link href="/expo" className="z-[1]" data-sentry-element="Link" data-sentry-source-file="Header.tsx">
          <ExpoLogoIcon data-sentry-element="ExpoLogoIcon" data-sentry-source-file="Header.tsx" />
        </Link>
        <HamburgerMenuIcon className="md:hidden z-[1]" active={active} onClick={() => setActive(!active)} data-sentry-element="HamburgerMenuIcon" data-sentry-source-file="Header.tsx" />
        <div className={cx("md:flex gap-[32px] md:sticky fixed md:h-auto h-[100vh] top-0 md:bg-transparent bg-[rgba(77,77,77,.98)] left-0 md:flex-row flex-col md:w-auto w-full justify-center items-center", active ? "flex" : "hidden")}>
          {links?.map(link => <Link key={link?.name} href={link?.url} className="text-white text-[22px] font-[400]">
              {link?.name}
            </Link>)}
          <div className="flex text-white text-[22px] font-[400] items-center gap-[4px] ml-[12px]">
            <Link href={""} locale="uz" className={cx("text-white text-[22px] font-[400]", locale === "uz" && "underline")} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
              UZ
            </Link>
            /
            <Link href={""} locale="ru" className={cx("text-white text-[22px] font-[400]", locale === "ru" && "underline")} data-sentry-element="Link" data-sentry-source-file="Header.tsx">
              RU
            </Link>
          </div>
        </div>
      </div>
    </div>;
};
export default Header;